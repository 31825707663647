import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import enLocale from "date-fns/locale/en-US"
import jaLocale from "date-fns/locale/ja"
import moment from "moment"
import React from "react"

import JaLocalizedUtils from "@/entity/service/jaLocalizedUtils"

const localeMap = {
    ja: jaLocale,
    en: enLocale,
}

const localeFormatMap = {
    ja: "yyyy年 M月d日",
    en: "d MMMM, yyyy",
}

const localeCancelLabelMap = {
    ja: "キャンセル",
    en: "cancel",
}

export default function DateRangePicker(props) {
    const { rangeDate, setRangeDate } = props
    const [locale] = React.useState("ja")

    const handleDateChange = (date) => {
        setRangeDate({ ...rangeDate, startDate: moment(date) })
    }

    const handleDateChange2 = (date) => {
        setRangeDate({ ...rangeDate, endDate: moment(date) })
    }

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
            }}
        >
            <MuiPickersUtilsProvider
                utils={JaLocalizedUtils}
                locale={localeMap[locale]}
                style={{ color: "white", width: "150px" }}
            >
                <DatePicker
                    //disableToolbar
                    disableFuture
                    views={["year", "month", "date"]}
                    minDate={new Date("2019-10-01")}
                    maxDate={moment(rangeDate.endDate)}
                    value={rangeDate.startDate}
                    onChange={handleDateChange}
                    inputProps={{ style: { fontSize: "16px", width: "128px" } }}
                    format={localeFormatMap[locale]}
                    cancelLabel={localeCancelLabelMap[locale]}
                    animateYearScrolling={true}
                />
            </MuiPickersUtilsProvider>
            <div style={{ margin: "auto 14px" }}>〜</div>
            <MuiPickersUtilsProvider
                utils={JaLocalizedUtils}
                locale={localeMap[locale]}
                style={{ color: "white", width: "150px" }}
            >
                <DatePicker
                    disableFuture
                    // disableToolbar
                    views={["year", "month", "date"]}
                    minDate={moment(rangeDate.startDate)}
                    maxDate={moment()}
                    value={rangeDate.endDate}
                    onChange={handleDateChange2}
                    inputProps={{ style: { fontSize: "16px", width: "128px" } }}
                    format={localeFormatMap[locale]}
                    cancelLabel={localeCancelLabelMap[locale]}
                    animateYearScrolling={true}
                    minDateMessage={"正しい範囲ではありません。"}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}
