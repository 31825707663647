import "date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import enLocale from "date-fns/locale/en-US"
import jaLocale from "date-fns/locale/ja"
import moment from "moment"
import React from "react"

import JaLocalizedUtils from "@/entity/service/jaLocalizedUtils"

const localeMap = {
    ja: jaLocale,
    en: enLocale,
}

const localeFormatMap = {
    ja: "yyyy年 M月d日",
    en: "d MMMM, yyyy",
}

const localeCancelLabelMap = {
    ja: "キャンセル",
    en: "cancel",
}

const DayPicker = (props) => {
    const { rangeDate, setRangeDate } = props
    const [locale] = React.useState("ja")

    const handleDateChange = (date) => {
        setRangeDate({
            startDate: moment(date),
            endDate: moment(date),
        })
    }

    return (
        <MuiPickersUtilsProvider
            utils={JaLocalizedUtils}
            locale={localeMap[locale]}
            style={{ color: "white", width: "112px" }}
        >
            <DatePicker
                style={{ width: "160px" }}
                views={["year", "month", "date"]}
                minDate={new Date("2019-10-01")}
                maxDate={new Date()}
                value={rangeDate.startDate}
                onChange={handleDateChange}
                inputProps={{ style: { fontSize: "20px" } }}
                format={localeFormatMap[locale]}
                cancelLabel={localeCancelLabelMap[locale]}
                animateYearScrolling={true}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DayPicker
