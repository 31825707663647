import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import enLocale from "date-fns/locale/en-US"
import jaLocale from "date-fns/locale/ja"
import moment from "moment"
import React from "react"

const localeMap = {
    ja: jaLocale,
    en: enLocale,
}
const localeUtilsMap = {
    ja: DateFnsUtils,
    en: DateFnsUtils,
}

const localeFormatMap = {
    ja: "yyyy年 M月",
    en: "d MMMM, yyyy",
}

const localeCancelLabelMap = {
    ja: "キャンセル",
    en: "cancel",
}

export default function MonthPicker(props) {
    const { rangeDate, setRangeDate } = props
    const [locale] = React.useState("ja")

    const handleDateChange = (date) => {
        //setSelectDate(date);
        setRangeDate({
            startDate: moment(date).startOf("month"),
            endDate: moment(date).endOf("month"),
        })
    }

    return (
        <MuiPickersUtilsProvider
            utils={localeUtilsMap[locale]}
            locale={localeMap[locale]}
            style={{ color: "white", width: "112px" }}
        >
            <DatePicker
                style={{ width: "125px" }}
                views={["year", "month"]}
                minDate={new Date("2019-10-01")}
                maxDate={new Date()}
                value={rangeDate.startDate}
                onChange={handleDateChange}
                inputProps={{ style: { fontSize: "20px" } }}
                format={localeFormatMap[locale]}
                cancelLabel={localeCancelLabelMap[locale]}
                animateYearScrolling={true}
            />
        </MuiPickersUtilsProvider>
    )
}
