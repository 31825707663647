import "date-fns"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import moment from "moment"
import React from "react"

import Theme from "../../entity/theme/theme"

import DateRangePicker from "./dateRangePicker"
import MonthPicker from "./monthPicker"

import DayPicker from "@/presentation/molecules/dayPicker"

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        // padding: "6px 0px 0px",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: Theme.font.fontFamily,
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
    },
    picker: {
        flex: "0 0 50%",
    },
    previousRear: {
        flex: "0 0 20%",
    },
    customPreviousRear: {
        flex: "0 0 15%",
    },
    iconButton: {
        fontSize: "12px",
    },
}))

const SelectPicker = React.memo((props) => {
    const { rangeDate, setRangeDate, selectedPicker } = props
    const classes = useStyles()

    const handleDateNext = () => {
        let difference = moment(rangeDate.endDate).diff(rangeDate.startDate)

        switch (selectedPicker) {
            case "day":
                setRangeDate({
                    startDate: moment(moment(rangeDate.startDate).add(1, "d")).startOf("day"),
                    endDate: moment(moment(rangeDate.endDate).add(1, "d")).endOf("day"),
                })
                break
            case "month":
                setRangeDate({
                    startDate: moment(moment(rangeDate.startDate).add(1, "M")).startOf("month"),
                    endDate: moment(moment(rangeDate.endDate).add(1, "M")).endOf("month"),
                })
                break
            case "custom":
                setRangeDate({
                    startDate: moment(moment(rangeDate.startDate).add(difference)).startOf("day"),
                    endDate: moment(moment(rangeDate.endDate).add(difference)).startOf("day"),
                })
                break
        }
    }
    const handleDateBack = () => {
        let difference = moment(rangeDate.endDate).diff(rangeDate.startDate)

        switch (selectedPicker) {
            case "day":
                setRangeDate({
                    startDate: moment(moment(rangeDate.startDate).subtract(1, "d")),
                    endDate: moment(moment(rangeDate.endDate).subtract(1, "d")),
                })
                break
            case "month":
                setRangeDate({
                    startDate: moment(moment(rangeDate.startDate).subtract(1, "M")).startOf(
                        "month"
                    ),
                    endDate: moment(moment(rangeDate.endDate).subtract(1, "M")).endOf("month"),
                })
                break
            case "custom":
                setRangeDate({
                    startDate: moment(moment(rangeDate.startDate).subtract(difference)).startOf(
                        "day"
                    ),
                    endDate: moment(moment(rangeDate.endDate).subtract(difference)).startOf("day"),
                })
                break
        }
    }

    const checkDisabled = () => {
        let difference = moment(rangeDate.endDate).diff(rangeDate.startDate)
        switch (selectedPicker) {
            case "day":
                return (
                    moment(rangeDate.endDate).format("M") == moment().format("M") &&
                    moment(rangeDate.endDate).format("YYYY") == moment().format("YYYY") &&
                    moment(rangeDate.endDate).format("DD") == moment().format("DD")
                )
            case "month":
                return (
                    moment(rangeDate.startDate).format("M") == moment().format("M") &&
                    moment(rangeDate.startDate).format("YYYY") == moment().format("YYYY")
                )
            case "custom":
                return (
                    moment(rangeDate.endDate).add(difference).startOf("day") >
                    moment().startOf("day")
                )
        }
    }

    return (
        <div className={classes.root}>
            {selectedPicker == "day" && (
                <>
                    <div className={classes.previousRear}>
                        <IconButton
                            color="default"
                            onClick={handleDateBack}
                            className={classes.iconButton}
                        >
                            <KeyboardArrowLeftIcon />
                            前日
                        </IconButton>
                    </div>
                    <div className={classes.picker}>
                        <DayPicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
                    </div>
                    <div className={classes.previousRear}>
                        <IconButton
                            color="default"
                            onClick={handleDateNext}
                            className={classes.iconButton}
                            disabled={checkDisabled()}
                        >
                            明日
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </div>
                </>
            )}
            {selectedPicker == "month" && (
                <>
                    <div className={classes.previousRear}>
                        <IconButton
                            color="default"
                            onClick={handleDateBack}
                            className={classes.iconButton}
                        >
                            <KeyboardArrowLeftIcon />
                            前月
                        </IconButton>
                    </div>
                    <div className={classes.picker}>
                        <MonthPicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
                    </div>
                    <div className={classes.previousRear}>
                        <IconButton
                            color="default"
                            onClick={handleDateNext}
                            className={classes.iconButton}
                            disabled={checkDisabled()}
                        >
                            次月
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </div>
                </>
            )}
            {selectedPicker == "custom" && (
                <>
                    <div className={classes.customPreviousRear}>
                        <IconButton
                            color="default"
                            onClick={handleDateBack}
                            className={classes.iconButton}
                        >
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                    </div>
                    <div className={classes.picker}>
                        <DateRangePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
                    </div>
                    <div className={classes.customPreviousRear}>
                        <IconButton
                            color="default"
                            onClick={handleDateNext}
                            className={classes.iconButton}
                            disabled={checkDisabled()}
                        >
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </div>
                </>
            )}
        </div>
    )
})

export default SelectPicker
